export const CONTEXTS: string[] = ['h', 'f']

export const PRISMIC_CONTEXT: { [key: string]: string } = {
  h: 'Homme',
  f: 'Femme',
}

export const SLICE_PRODUCT_LIST: string[] = [
  'products',
  'product_two_cols',
  'products_edito',
  'product_video',
  'product_highlight',
  'multi_cta_image',
  'double_produit_highlight',
]

export enum CURRENCY {
  EUR = 'EUR',
  USD = 'USD',
}

export enum ACCOUNT_STATUS {
  GUEST_ACCOUNT = 'DISABLED',
  NO_ACCOUNT = 'NOT_FOUND',
  HAS_ACCOUNT = 'ENABLED',
}

export enum ORIGIN {
  HOME = 'home',
  ESHOP = 'eshop',
  STOCK = 'stock',
  AGENDA = 'agenda',
  AGENDA_TIMELINE = 'agenda-timeline',
  ARTICLE = 'article',
  PRODUCT = 'product',
  PRODUCT_TEASING = 'product-teasing',
  PAGE = 'page',
  BLOG = 'blog',
  FOOTER = 'footer',
  UNSUBSCRIBE = 'unsubscribe',
  MY_ORDERS = 'my-orders',
  SURVEY = 'survey',
  MODAL = 'modal',
  ACCOUNT = 'account',
}

export enum IMPACTS {
  CARBON = 'Carbone',
  WATER = 'Eau',
  ENERGY = 'Energie',
  PHOSPHATE = 'Phosphate',
}

// Ces structures doivent être iso avec celle renseignée côté prismic

/*
 * PRISMIC
 * Custom_type : Product
 * Emplacement : Slice Module
 * Name : status
 */
export enum STATUS {
  PRECOMMANDE = 'precommande',
  AGENDA = 'agenda',
  TEASING = 'teasing',
  STOCK = 'stock',
}

/*
 * PRISMIC
 * Custom_type : Product
 * Emplacement : Primary
 * Name : product_type
 */
export enum TYPES {
  NORMAL = 'normal',
  SUIT = 'suit',
  GIFTCARD = 'giftcard',
}

/*
 * PRISMIC
 * Custom_type : Product
 * Emplacement : Primary
 * Name : market_product_type
 */
export enum MARKET_TYPES {
  NORMAL = 'normal',
  ETERNEL = 'eternel',
}

export enum MARKET_ARGUMENT {
  AUTOMATIQUE = 'Automatique',
  TEXTE = 'Texte',
}

export enum MARKET_ARGUMENT_TAG {
  AUTOMATIQUE = 'Automatique',
  NEW = 'New',
  TEASING = 'Teasing',
  TIMER = 'Timer',
  NUMBER = 'Number',
  LASTCHANCE = 'LastChance',
  COCREATION = 'CoCreation',
  NEWCOLOR = 'NewColor',
}

export enum COLLECTION_PRODUCT_TYPES {
  NORMAL = 'normal',
  FULL = 'pleine-page',
  SLIDER = 'slider',
  GRID = 'grid',
  REF_CO = 'ref/co',
}

export enum IMAGE_FORMAT {
  PORTRAIT = 'portrait',
  SQUARE = 'carre',
  LANDSCAPE = 'paysage',
}

export enum TEXT_POSITION {
  LEFT = 'gauche',
  CENTER = 'centre',
  RIGHT = 'droite',
}

export enum CONTENT_HIDDEN_LIST {
  FALSE = 'Non',
  HOME = 'Accueil',
  ESHOP = 'Eshop',
  ALL = 'Partout',
}

/*
 * PRISMIC
 * Custom_type : Page
 * Emplacement : Slice Background Content
 * Name : klaviyo_form
 */
export enum FORMS {
  NONE = 'Pas de formulaire',
  DEFAULT = 'Newsletter Homme/Femme',
  KLAVIYO_LIST = 'Email pour une liste spécifique',
}
// END PRISMIC

/*
 * COOKIES
 */
export const COOKIE_EMAIL_KEY = 'asphalte-email'
export const COOKIE_BIS_KEY = 'asphalte-bis'

/*
 * LOCALE STORAGE
 */
export const LS_ACCESS_TOKEN = 'asphalte-access-token'
export const LS_TOKEN_EXP_DATE = 'asphalte-token-exp-date'
export const LS_CHECKOUT_ID = 'asphalte-checkout-id'
export const LS_ORDER = 'asphalte-order'
export const LS_ORDER_SHIPPING = 'asphalte-order-shipping'
export const LS_ORDER_STATUS = 'asphalte-order-status'

/*
 * SESSION STORAGE
 */
export const SS_UTMS = 'asphalte-utms'
export const SS_SOURCES = 'asphalte-sources'

/**
 * TRANSITIONS
 */
export enum TRANSITIONTYPE {
  left,
  right,
  filter,
}

/**
 * SHOPIFY TYPES
 */
export enum SHOPIFY_TYPES {
  COLLECTION = 'Collection',
  PRODUCT = 'Product',
  VARIANT = 'ProductVariant',
}

/**
 * DISPLAY LIST
 */
export enum DISPLAY {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
}

/**
 * ERRORS TYPE
 */
export enum ERRORS_TYPE {
  NONE,
  EMPTY = 'empty',
  FAIL = 'fail',
}

/**
 * UPSELLING
 */
export enum UPSELLING_TYPES {
  LINK = 'link',
  PRODUCTS = 'products',
}

export interface IUpsellingProduct {
  id: number
  slug: {
    fr?: string
    en?: string
    de?: string
  }
}

export interface IUpsellingGroupLink {
  type: UPSELLING_TYPES
  origins: number[]
  weight: number
  target: IUpsellingProduct
  context: string
  canCompleteList: boolean
  tag: {
    fr?: string
    en?: string
    de?: string
  }
}

export interface IUpsellingGroupProducts {
  type: UPSELLING_TYPES
  name: string
  weight: number
  canCompleteList: boolean
  context: string
  products: IUpsellingProduct[]
  tag: {
    fr?: string
    en?: string
    de?: string
  }
}

export type upsellingGroup = IUpsellingGroupProducts | IUpsellingGroupLink

export const UPSELLING_LIMIT = 4

export const UPSELLING_TRADS = [
  'Les éternels',
  'Cluster',
  'La housse de costume',
]
